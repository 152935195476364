import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { openRenameProjectModal as _openRenameProjectModal } from 'store/modal/actions';
import { DetailsTitle, DetailsParagraph, ProjectNameWrapper } from './styled';
import Show from 'components/Functional/Show';
import Icon from 'components/icons';
import { DeviceSize, useWindowSize } from 'hooks/useWindowSize';
import { transformToEllipsisText } from 'helpers/strings';

interface ProductDetailsProps {
  title: string;
  details: string[];
  className?: string;
  asHtml?: boolean;
  projectName?: string;
  renameProjectHandler: (newName: string) => void;
  handleItemClick: () => void;
}

const DetailsBlock = ({
  title,
  details,
  className,
  asHtml = false,
  projectName,
  renameProjectHandler,
  handleItemClick
}: ProductDetailsProps) => {
  const dispatch = useDispatch();

  const openRenameProjectModal = useCallback(() => {
    dispatch(_openRenameProjectModal({ projectName, renameProjectHandler, scrollPosition: window.scrollY }));
  }, [renameProjectHandler, projectName, dispatch]);

  const { deviceSize } = useWindowSize();
  const isMobile = deviceSize === DeviceSize.SMALL;

  const formatProjectName = () => {
    if (isMobile) {
      return transformToEllipsisText(projectName, 47, isMobile);
    }
    if (!isMobile) {
      return transformToEllipsisText(projectName, 45, isMobile);
    }
    return projectName;
  };

  return (
    <div className={`ProductDetails ${className}`}>
      <DetailsTitle secondary onClick={handleItemClick}>{title}</DetailsTitle>
      <Show when={!!projectName}>
        <ProjectNameWrapper onClick={openRenameProjectModal}>
          <DetailsParagraph title={projectName}>{`Project: ${formatProjectName()}`}</DetailsParagraph>
          <Icon icon="pencil" viewBox="21 21 21 21" />
        </ProjectNameWrapper>
      </Show>
      {details.map((detail: string, i: number) =>
        asHtml ? (
          <DetailsParagraph key={i} dangerouslySetInnerHTML={{ __html: detail }} />
        ) : (
          <DetailsParagraph key={i}>{detail}</DetailsParagraph>
        )
      )}
    </div>
  );
};

export default DetailsBlock;
