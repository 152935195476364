import React from 'react';
import CatalogImage from 'components/CatalogImage';
import { adjustHiDPI, isVideo } from 'components/helpers/ui';
import { MediaObject } from 'types/media';
import { LivePreviewContainer, LivePreviewContainerInner, TwoColumnContainer } from './styled';
import { VimeoVideoContainer } from 'components/Vimeo/VimeoVideoContainer';

interface PDPMediaObject extends MediaObject {
  isMobile: boolean;
  zoom?: boolean;
  handleMobileProductImageGalleryOpen?: (src: string) => void;
  modalImageZoom?: boolean;
}

interface ReactImageGalleryItem extends MediaObject {
  originalWidth: string;
  originalHeight: string;
  renderItem: (props) => JSX.Element;
  isMobile: boolean;
  handleMobileProductImageGalleryOpen: (src: string) => void;
}

export const renderPhoto = (
  pdpMedia: PDPMediaObject,
  handleDesktopProductImageGalleryOpen?: (src: string) => void
): JSX.Element => {
  const { isMobile, url, caption, handleMobileProductImageGalleryOpen, modalImageZoom } = pdpMedia;

  return (
    <CatalogImage
      key={`pdp-image-${isMobile ? 'mobile-' : 'desktop-' + url}`}
      alt={caption}
      src={url}
      css={{ borderRadius: '2px' }}
      fullscreenOnMobile
      widths={{
        tiny: adjustHiDPI(400), // Small breakpoint is 376px
        small: adjustHiDPI(600), // Medium breakpoint is 576px
        medium: adjustHiDPI(800), // The largest the image will render here is 100vw at 768px breakpoint
        large: adjustHiDPI(1000) // The max width of the GlobalLayout wrapper is set to 1180px, this image will never render larger than half of that
      }}
      zoom={false}
      handleProductImageGalleryOpen={handleDesktopProductImageGalleryOpen || handleMobileProductImageGalleryOpen}
      modalImageZoom={modalImageZoom}
      height="100%"
    />
  );
};


export const renderVideo = (
  pdpMedia: PDPMediaObject,
  handleDesktopProductImageGalleryOpen?: (src: string) => void
): JSX.Element => {
  const { videoId, isMobile, videoUrl, handleMobileProductImageGalleryOpen } = pdpMedia;

  return (
    <VimeoVideoContainer
      key={videoUrl}
      videoId={videoId}
      isMobile={isMobile}
      onClick={() => {
        handleDesktopProductImageGalleryOpen
          ? handleDesktopProductImageGalleryOpen(videoUrl)
          : handleMobileProductImageGalleryOpen(videoUrl);
      }}
    />
  );
};

const sortPhotos = (photos: MediaObject[]): MediaObject[] =>
  photos.sort((a, b) => {
    if (a.isMain) {
      return -1;
    }
    if (b.isMain) {
      return 1;
    }
    return a.videoUrl ? -1 : 1;
  }).map(photo => ({ ...photo, isMobile: false }));

export const getMobileItems = (
  photos: MediaObject[],
  livePreviewUrl: string,
  handleProductImageGalleryOpen: (src: string) => void
): ReactImageGalleryItem[] => {
  const newPhotos = [...photos];
  const sortedPhotos = sortPhotos(newPhotos);
  if (livePreviewUrl) {
    const livePreviewImage = {
      full: livePreviewUrl,
      img: livePreviewUrl,
      isMain: false,
      thumb: livePreviewUrl,
      url: livePreviewUrl,
      type: 'image',
      videoId: null,
      videoUrl: null,
      position: null,
      caption: null
    };

    sortedPhotos.splice(1, 0, livePreviewImage);
  }
  const mappedForCarousel = sortedPhotos.map(photo => ({
    originalWidth: '300px',
    originalHeight: '400px',
    renderItem: isVideo(photo) ? renderVideo : renderPhoto,
    isMobile: true,
    handleMobileProductImageGalleryOpen: handleProductImageGalleryOpen,
    ...photo
  }));

  return mappedForCarousel.filter(Boolean);
};

export const getDesktopImages = (
  photos: MediaObject[],
  handleProductImageGalleryOpen: (src: string) => void
): JSX.Element[] => {
  const newPhotos = [...photos];

  const sortedPhotos = sortPhotos(newPhotos).map(photo => ({ ...photo, isMobile: false }));
  const rendered = sortedPhotos.map(photo => {
    return isVideo(photo)
      ? renderVideo(photo, handleProductImageGalleryOpen)
      : renderPhoto(photo, handleProductImageGalleryOpen);
  });

  return groupPhotos(rendered.filter(Boolean));
};

const renderedMediaIsVideo = (renderedMedia: JSX.Element) => renderedMedia.key && renderedMedia.key.includes('video');

export const groupPhotos = (renderedPhotos: JSX.Element[]): JSX.Element[] => {
  const arrangedPhotos = renderedPhotos.slice(0, 2).map((photo) => {
    // We need to wrap the live preview in a container so that it 
    // doesn't disappear when live preview changes.  Without a container, the image will disappear
    // while the new livepreview image loads.
    if (renderedMediaIsVideo(photo)) {
      return photo;
    }
    return <LivePreviewContainer key={photo.key}><LivePreviewContainerInner>{photo}</LivePreviewContainerInner></LivePreviewContainer>;
  }); // First two are unchanged

  for (let i = 2; i < renderedPhotos.length; ) {
    // Begin with photo 3 and maybe combine
    let group = null;

    if (arrangedPhotos.length === 3 && renderedPhotos[i]) {
      // First Single after group
      arrangedPhotos.push(renderedPhotos[i]);
      i++;
      continue;
    }

    if (renderedPhotos[i] && renderedPhotos[i + 3]) {
      // Enough for 4up
      group = renderedPhotos.slice(i, i + 4); // End of slice not included
      i += 4;
    } else if (renderedPhotos[i] && renderedPhotos[i + 1]) {
      // Enough for 2up
      group = renderedPhotos.slice(i, i + 2);
      i += 2;
    } else {
      // This will be the last photo
      arrangedPhotos.push(renderedPhotos[i]);
      i++;
      continue;
    }

    arrangedPhotos.push(
      <TwoColumnContainer key={`two-column-${i}`} className="two-column-image-container">
        {group}
      </TwoColumnContainer>
    );
  }

  return arrangedPhotos;
};
