import React from 'react';
import styled from 'styled-components';

import { StyledH3, mediaQuery, CatalogImage } from 'components';

import { Product } from 'types/Product';
import { AULink } from 'components/AULink';
import { toAbsolutePath } from 'helpers/navigation';
import { CATALOG_IMAGE_RESPONSIVE_SIZES } from 'constants/images';

const UpsellProductContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 30px;

  ${mediaQuery.medium`
    flex-direction: row;
  `}
`;

const Image = styled.div`
  width: 100%;
  max-width: 220px;
  margin: 0 auto;

  ${mediaQuery.medium`
    width: 40%
  `}
`;

const Details = styled.div`
  width: 100%;
  padding: 0 10px 0 25px;

  ${mediaQuery.medium`
    width: 60%;
    max-width: 520px;
  `}
`;

const Name = styled.div`
  margin-bottom: 9px;
  font-size: 17px;
`;

const Price = styled.div`
  margin-bottom: 15px;
  font-size: 17px;
`;

const Description = styled.div`
  padding-top: 10px;
  margin-bottom: 21px;
  font-size: 14px;
  font-weight: 300;

  p {
    margin: 0;
  }
`;

const PrimaryButtonLink = styled(AULink)`
  background-color: rgb(0, 0, 0);
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  font-size: 0.6875rem;
  line-height: 1.35rem;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  text-align: center;
  display: inline-block;
  height: auto;
  width: 100%;
  cursor: pointer;
  color: white !important;
  outline: 0px;
  border-radius: 0px;
  padding: 1rem;
`;

interface UpsellProductProps {
  product: Product
}

function UpsellProduct(props: UpsellProductProps) {
  const { product } = props;

  return (
    <UpsellProductContainer>
      <Image>
        <AULink path={toAbsolutePath(product.canonicalUrl || product.urlKey)} linkToGatsby={product.gatsbyPageExists}>
          <CatalogImage
            src={product.image}
            alt={`Image for ${product.name}`}
            widths={CATALOG_IMAGE_RESPONSIVE_SIZES}
          />
        </AULink>
      </Image>
      <Details>
        <AULink path={toAbsolutePath(product.canonicalUrl || product.urlKey)} linkToGatsby={product.gatsbyPageExists}>
          <Name>{product.name}</Name>
          <Price>{determineLowestPrice(product)}</Price>
          <Description dangerouslySetInnerHTML={{ __html: product.description }} />
        </AULink>
        <PrimaryButtonLink
          role="button"
          path={toAbsolutePath(product.canonicalUrl || product.urlKey)}
          linkToGatsby={product.gatsbyPageExists}
        >
          See Product
        </PrimaryButtonLink>
      </Details>
    </UpsellProductContainer>
  );
}

const UpsellContainer = styled.ul`
  max-width: 1180px;

  padding: 1rem;
  margin: auto;
`;

const UpsellProductsContainer = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0;

  ${mediaQuery.medium`
    flex-direction: row;
    flex-wrap: wrap;
  `};

  > li {
    list-style: none;
    width: 100%;
    margin-bottom: 15px;

    ${mediaQuery.medium`
      width: 50%;
    `};
  }
`;

const UpsellHeading = styled(StyledH3)`
  text-align: center;
  font-size: 32px;
  line-height: 46px;
`;

interface UpsellProps {
  products: Product[]
}

export function Upsell(props: UpsellProps) {
  const { products } = props;

  if (!products.length) {
    return null;
  }

  return (
    <UpsellContainer>
      <UpsellHeading secondary>Pair it with...</UpsellHeading>
      <UpsellProductsContainer>
        {products.map(product => (
          <li key={product.sku}>
            <UpsellProduct product={product} />
          </li>
        ))}
      </UpsellProductsContainer>
    </UpsellContainer>
  );
}

function determineLowestPrice(product: Product) {
  const price = product.lowestPrice ? `From $${product.lowestPrice}` : `$${product.price}`;
  return price;
}
