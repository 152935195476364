import { mediaQuery, StyledP } from 'components';
import styled, { css } from 'styled-components';
import { StyledH3 } from './typography';

interface RelatedProductsProps {
  showMobilePDPTest: boolean;
}

export const RelatedProductsContainer = styled.div<RelatedProductsProps>`
  margin: auto;
  padding: 1rem;
  max-width: ${({ showMobilePDPTest }) => (showMobilePDPTest ? 'none' : '1180px')};

  ${({ showMobilePDPTest }) =>
    showMobilePDPTest &&
    css`
      /* 
        This creates a full-width container 
        that breaks out of the global wrapper padding.
      */
      width: 100vw;
      position: relative;
      left: 50%;
      right: 50%;
      margin-left: -50vw;
      margin-right: -50vw;
      padding-left: calc(50vw - 54% + 1rem);
      padding-right: calc(50vw - 54% + 1rem);
    `}
`;

export const ProductBlockContainer = styled.div<RelatedProductsProps>`
  display: flex;

  ${({ showMobilePDPTest }) =>
    showMobilePDPTest
      ? css`
          /* 
            This lays the products out horizontally and 
            allows for horizontal scroll on mobile
          */
          margin: 0 -1rem; // Negative margin to counteract parent padding
          overflow-x: auto;
          overflow-y: hidden;
          white-space: nowrap;
          -webkit-overflow-scrolling: touch;
          padding: 8px 1rem 8px 0; // Right padding to ensure last item is fully visible

          // Pseudo-element for the fixed border
          &::after {
            content: '';
            position: absolute;
            bottom: 0;
            margin-bottom: 1rem;
            left: 1rem; // Align with parent's left padding
            right: 1rem; // Align with parent's right padding
            height: 1px;
            background-color: #e0e0e0;
            pointer-events: none; // Ensures the border doesn't interfere with scrolling
          }

          &::-webkit-scrollbar {
            display: none;
          }

          -ms-overflow-style: none;
          scrollbar-width: none;

          > div {
            flex: 0 0 auto;
            width: calc(38.38% - 16px);
            margin: 0 0 0 16px; // Increased left margin
            white-space: normal;

            &:first-child {
              margin-left: 1rem; // Align first item with parent's left padding
            }

            ${mediaQuery.medium`
          width: calc(50% - 16px);
        `};

            ${mediaQuery.large`
          width: calc(33.33% - 16px);
        `};
          }
        `
      : css`
          margin: 0 -8px;
          flex-wrap: wrap;

          > div {
            flex-grow: 1;
            margin: 8px;
            flex-basis: calc(50% - 16px);
            max-width: calc(50% - 16px);

            ${mediaQuery.medium`
                flex-basis: calc(33% - 16px);
                max-width: calc(33% - 16px);
            `};

            ${mediaQuery.large`
                flex-basis: calc(20% - 16px);
                max-width: calc(20% - 16px);
            `};
          }
        `}

    img {
        max-width: 100%;
        height: auto;
    }
`;

export const RelatedProductsTitle = styled(StyledH3)<RelatedProductsProps>`
  ${({ showMobilePDPTest }) =>
    showMobilePDPTest
      ? css`
          text-align: left;
          margin-bottom: 0;
          font-size: 16px;
        `
      : css`
          text-align: center;
          font-size: 1.375rem;;
        `}
`;

export const ProductBlockWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  position: relative;
`;

export const ProductBadge = styled.div`
  z-index: 1;
  position: absolute;
  padding: 12px;
  ${mediaQuery.medium`
    padding: 16px;
  `};
`;

export const ProductDetailsWrapper = styled.div<RelatedProductsProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: ${({ showMobilePDPTest }) => (showMobilePDPTest ? '5px 0' : '15px 0')};

  ${StyledP} {
    text-align: center;
    margin: 2px 0;
  }
`;
