import React from 'react';
import { HeaderTitleWrapper, BackButton, BackText, CloseButton, HeaderContainer, CloseIcon } from './styled';
import { Icon, StyledLabel, Column, StyledH2, Grid } from 'components';
import { Dispatch } from 'redux';
import { openPreviousModal as _openPreviousModal } from '../../store/modal/actions';
import { connect } from 'react-redux';
import { GlobalState } from 'store/constants';
import { modalHasHistorySelector } from 'store/modal/selectors';
import { Show } from 'components/Functional';

interface ModalHeaderProps {
  openPreviousModal: () => void;
  hasHistory: boolean;
  title: string;
}

const ModalHeader = ({ openPreviousModal, hasHistory, title }: ModalHeaderProps) => (
  <div>
    {title === 'Edit contact' ? (
      <HeaderContainer>
        <Column size={8}>
          <StyledH2 secondary>{title}</StyledH2>
        </Column>
        <Column size={2}>
          <Show when={hasHistory}>
            <CloseButton onClick={openPreviousModal}>
              <CloseIcon icon="close" size="small" />
            </CloseButton>
          </Show>
        </Column>
      </HeaderContainer>
    ) : (
      <Grid>
        <Column size={2}>
          <Show when={hasHistory}>
            <BackButton onClick={openPreviousModal}>
              <Icon icon="back" />
              <BackText>
                <StyledLabel>Back</StyledLabel>
              </BackText>
            </BackButton>
          </Show>
        </Column>
        <Column size={8}>
          <HeaderTitleWrapper>
            <StyledH2 secondary>{title}</StyledH2>
          </HeaderTitleWrapper>
        </Column>
        <Column size={2}></Column>
      </Grid>
    )}
  </div>
);

const mapStateToProps = (state: GlobalState) => ({
  hasHistory: modalHasHistorySelector(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  openPreviousModal: () => dispatch(_openPreviousModal())
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalHeader);
