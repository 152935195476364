import React, { useState } from 'react';
import {
  Grid,
  SuccessNotification,
  ErrorNotification,
  Field,
  ErrorLabel,
  Column,
  StyledP,
  PrimaryLoadingButton
} from 'components';
import Itly from 'itly';
import { Formik, FormikErrors, FormikActions } from 'formik';
import { isValidEmail, isPasswordValid } from 'helpers/strings';
import { createCustomer, newsletterSignUp } from 'services/magenum/customer';
import {
  StyledRegisterForm,
  CenteredHeader,
  CenteredText,
  InlineP,
  StyledLogo,
  PasswordMaskButtonWithIcon,
  RelativeStyleLabel
} from './styled';
import { useDispatch } from 'react-redux';
import { hideModal as _hideModal, openCustomerLoginModal as _openCustomerLoginModal } from 'store/modal/actions';
import { customerLoginSuccess, fetchCustomerSuccess } from 'store/customer/actions';
import { fetchCartRequest } from 'store/cart/actions';
import CustomerDataCustomer from 'mage-swagfaces/customer/CustomerDataCustomer';
import PasswordValidationList from './PasswordValidationList';
import { nrError } from 'helpers/reporting/newrelic';
import { analyticsWrapper } from 'analytics/itly/analyticsWrapper';
import { TrackingProp } from 'react-tracking';
import { trackAccountCreated } from 'analytics/itly/tracking';
import { ERROR_MESSAGE_GENERIC } from 'components/ErrorBoundary';
import SvgContainer from 'icons/SvgContainer';
import LogoSvg from '../../icons/Logo.svg';
import itly from 'itly';
import { useProductSelectors } from 'hooks/useProductSelectors';

const CREATE_ACCOUNT_MESSAGE = 'Creating an account allows us to save your work, so you can return to it later!';

interface CustomerRegistrationProps {
  tracking: TrackingProp;
  message?: string;
  redirect?: string;
}

interface RegisterFields {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  passwordConfirm: string;
}

const CustomerRegistration = ({
  tracking,
  message,
  redirect
}: CustomerRegistrationProps) => {
  const dispatch = useDispatch();
  const { getEditorVersion } = useProductSelectors();

  const hideModal = () => dispatch(_hideModal());
  const openCustomerLoginModal = () => dispatch(_openCustomerLoginModal({redirect: redirect}));
  const setCustomer = customer => dispatch(fetchCustomerSuccess(customer));
  const getCart = () => dispatch(fetchCartRequest()); 

  const [passwordValue, setPasswordValue] = useState('');
  const [confirmPasswordValue, setConfirmPasswordValue] = useState('');
  const [isPassValid, setIsPasswordValid] = useState(false);

  const [passwordMaskIcon, setPasswordMaskIcon] = useState<'openEye' | 'closedEye'>('closedEye');
  const [passwordFieldType, setPasswordFieldType] = useState<'text' | 'password'>('password');

  const [passwordConfirmMaskIcon, setPasswordConfirmMaskIcon] = useState<'openEye' | 'closedEye'>('closedEye');
  const [passwordConfirmFieldType, setPasswordConfirmFieldType] = useState<'text' | 'password'>('password');

  const togglePasswordMask = () => {
    // Toggle the type attribute
    const nextType = passwordFieldType === 'password' ? 'text' : 'password';
    setPasswordFieldType(nextType);

    // Toggle the eye
    const nextIcon = passwordMaskIcon === 'closedEye' ? 'openEye' : 'closedEye';
    setPasswordMaskIcon(nextIcon);
  };

  const togglePasswordConfirmMask = () => {
    // Toggle the type attribute
    const nextType = passwordFieldType === 'password' ? 'text' : 'password';
    setPasswordConfirmFieldType(nextType);

    // Toggle the eye
    const nextIcon = passwordMaskIcon === 'closedEye' ? 'openEye' : 'closedEye';
    setPasswordConfirmMaskIcon(nextIcon);
  };

  return (
    <>
      <CenteredHeader secondary>Create an Account</CenteredHeader>
      <CenteredText secondary>{message || CREATE_ACCOUNT_MESSAGE}</CenteredText>
      <Formik
        initialValues={
          {
            firstname: '',
            lastname: '',
            email: '',
            password: '',
            passwordConfirm: ''
          } as RegisterFields
        }
        onSubmit={async (values: RegisterFields, { setSubmitting, setStatus }: FormikActions<RegisterFields>) => {
          let createCustomerResponse;
          try {
            createCustomerResponse = await createCustomer({
              firstname: values.firstname,
              lastname: values.lastname,
              email: values.email,
              password: values.password
            });

            const customer = createCustomerResponse?.value?.customer;
            if (!customer) {
              throw new Error(createCustomerResponse?.value?.body?.message || ERROR_MESSAGE_GENERIC);
            }

            await newsletterSignUp(values.email).then(response => {
              response
                .map(x => {
                  itly.emailSubscribed({
                    customer_id: customer.id.toString(),
                    email: values.email,
                    subscription: 'subscribed',
                    location: 'account_creation',
                    last_updated: new Date().toISOString()
                  });
                })
                .getOrElseL(x => {
                  throw new Error(createCustomerResponse?.value?.body?.message || ERROR_MESSAGE_GENERIC);
                });
            });

            // Success! Show quick message, then close the modal
            setStatus({ success: "You've successfully registered." });
            setCustomer(customer);
            setSubmitting(false);
            getCart();

            if (redirect) {
              const url = new URL(redirect);
              const urlParams = url.searchParams;
              const redirectTo = urlParams.get('redirectTo');
              const message = urlParams.get('message');
              const hammerCategory = urlParams.get('tryEditorV2ForHammerCategory');
              const sku = urlParams.get('tryEditorV2ForSku');
              const editorVersion = getEditorVersion(sku);

              dispatch(customerLoginSuccess({
                editorVersion,
                redirectTo:
                redirectTo || redirect,
                hammerCategory,
                message,
                sku
              }));
            }

            setTimeout(() => {
              hideModal();
            }, 1000);
            trackAccountCreated(tracking, customer);
            Itly.loginCompleted({ login_method: 'Email', account_type: 'New' });
          } catch (error) {
            nrError('Registration error', error, {
              createCustomerResponse,
              createCustomerResponseValue: JSON.stringify(createCustomerResponse?.value),
              email: values.email
            });

            const responseErrorMessage = error?.message || 'The email is already in use. Please try again.';
            // Error! Show the magento message or a generic failure message
            setStatus({ error: responseErrorMessage });
            setSubmitting(false);
          }
        }}
        validate={values => {
          const [validPassword, invalidPasswordReason] = isPasswordValid(values.password || '');
          const errors: FormikErrors<RegisterFields> = {
            ...(!values.email && { email: 'Email address is required.' }),
            ...(!isValidEmail(values.email) && {
              email: 'Please enter a valid email address.'
            }),
            ...(!values.firstname && { firstname: 'First name is required.' }),
            ...(!values.lastname && { lastname: 'Last name is required.' }),
            ...(!values.password && { password: 'Password is required.' }),
            ...(values.password &&
              !(values.password.length >= 7) && {
                password: 'Password must be at least 7 characters.'
              }),
            ...(values.password && !validPassword && { password: invalidPasswordReason }),
            ...(!values.passwordConfirm && {
              passwordConfirm: 'Please confirm your password.'
            }),
            ...(values.passwordConfirm !== values.password && {
              password: 'Your passwords must match.'
            })
          };
          return errors;
        }}
        validateOnChange={false}
        render={({ values, errors, touched, setFieldValue, isSubmitting, status, submitForm }) => (
          <StyledRegisterForm
            data-testid="customerRegisterForm"
            onSubmit={e => {
              e.preventDefault();
              submitForm();
            }}
          >
            <Grid gutter={25}>
              {status && status.success ? (
                <Column size={12}>
                  <SuccessNotification message={status.success} />
                </Column>
              ) : null}
              <Column size={12} large={6}>
                <RelativeStyleLabel as="label">
                  {errors.firstname && touched.firstname && <ErrorLabel>{errors.firstname}</ErrorLabel>}
                  <Field
                    data-testid="customerRegisterForm::firstName"
                    name="firstname"
                    id="firstname"
                    autoFocus
                    placeholder="First Name"
                    type="text"
                    onChange={e => {
                      setFieldValue('firstname', e.target.value);
                    }}
                    border={errors.firstname && touched.firstname && '1px solid #C11E1E'}
                  />
                </RelativeStyleLabel>
              </Column>
              <Column size={12} large={6}>
                <RelativeStyleLabel as="label">
                  {errors.lastname && touched.lastname && <ErrorLabel>{errors.lastname}</ErrorLabel>}
                  <Field
                    name="lastname"
                    id="lastname"
                    placeholder="Last Name"
                    data-testid="customerRegisterForm::lastName"
                    onChange={e => {
                      setFieldValue('lastname', e.target.value);
                    }}
                    border={errors.lastname && touched.lastname && '1px solid #C11E1E'}
                  />
                </RelativeStyleLabel>
              </Column>
              <Column size={12}>
                <RelativeStyleLabel as="label">
                  {errors.email && touched.email && <ErrorLabel>{errors.email}</ErrorLabel>}
                  <Field
                    name="email"
                    type="email"
                    id="email"
                    placeholder="Email Address"
                    autoComplete="email"
                    value={values.email}
                    data-testid="customerRegisterForm::email"
                    onChange={e => {
                      setFieldValue('email', e.target.value);
                    }}
                    border={errors.email && touched.email && '1px solid #C11E1E'}
                  />
                </RelativeStyleLabel>
              </Column>
              <Column size={12} medium={6}>
                <RelativeStyleLabel as="label">
                  {errors.password && touched.password && <ErrorLabel>{errors.password}</ErrorLabel>}
                  <PasswordMaskButtonWithIcon
                    icon={passwordMaskIcon}
                    onClick={() => togglePasswordMask()}
                    accessibilityText="Password Mask"
                  />
                  <Field
                    name="password"
                    id="password"
                    type={passwordFieldType}
                    placeholder="Password"
                    autoComplete="password"
                    value={values.password}
                    data-testid="customerRegisterForm::password"
                    onChange={e => {
                      setFieldValue('password', e.target.value);
                      setPasswordValue(e.target.value);
                    }}
                    border={errors.password && touched.password && '1px solid #C11E1E'}
                  />
                </RelativeStyleLabel>
              </Column>
              <Column size={12} medium={6}>
                <RelativeStyleLabel as="label">
                  {errors.passwordConfirm && touched.passwordConfirm && (
                    <ErrorLabel>{errors.passwordConfirm}</ErrorLabel>
                  )}
                  <PasswordMaskButtonWithIcon
                    icon={passwordConfirmMaskIcon}
                    onClick={() => togglePasswordConfirmMask()}
                    accessibilityText="Password Mask"
                  />
                  <Field
                    name="passwordConfirm"
                    type={passwordConfirmFieldType}
                    id="passwordConfirm"
                    placeholder="Confirm Password"
                    data-testid="customerRegisterForm::passwordConfirm"
                    onChange={e => {
                      setFieldValue('passwordConfirm', e.target.value);
                      setConfirmPasswordValue(e.target.value);
                    }}
                    border={errors.passwordConfirm && touched.passwordConfirm && '1px solid #C11E1E'}
                  />
                </RelativeStyleLabel>
              </Column>
              <Column size={12}>
                <PasswordValidationList
                  password={passwordValue}
                  passwordConfirm={confirmPasswordValue}
                  setIsPasswordValid={setIsPasswordValid}
                />
              </Column>
              <Column size={12}>
                <PrimaryLoadingButton
                  id="create_account_button"
                  width="100%"
                  disabled={isSubmitting || !isPassValid}
                  isLoading={isSubmitting}
                  hoverColor="#000"
                  color="#333333"
                >
                  Create Account
                </PrimaryLoadingButton>
              </Column>
              {status && status.error ? (
                <Column size={12}>
                  <ErrorNotification message={status.error} />
                </Column>
              ) : null}
              <Column size={12}>
                <StyledP secondary alignment="center">
                  Have an account? &nbsp;
                  <InlineP
                    id="signIn"
                    onClick={() => openCustomerLoginModal()}
                    onKeyDown={e => e.key === 'Enter' && openCustomerLoginModal()}
                    tabIndex={0}
                  >
                    Sign In
                  </InlineP>
                  .
                </StyledP>
                <StyledP secondary alignment="center">
                  By clicking on Create Account, you agree to Artifact Uprising's
                  <br />
                  <a id="termsAndConditions" href="/terms-conditions" tabIndex={0}>
                    Terms & Conditions
                  </a>
                  &nbsp; and &nbsp;
                  <a id="privacyPolicy" href="/privacy" tabIndex={0}>
                    Privacy Policy
                  </a>
                  .
                </StyledP>
              </Column>
            </Grid>
            <StyledLogo>{SvgContainer(LogoSvg)}</StyledLogo>
          </StyledRegisterForm>
        )}
      />
    </>
  );
};

export default analyticsWrapper(CustomerRegistration);
