import styled from 'styled-components';
import { Icon, StyledH2, StyledP, ButtonWithIcon, GenericOverlay, StyledLabel } from 'components';

export const StyledRegisterForm = styled.form`
  max-width: 30rem;
  margin: 0 auto;
  border-radius: 25px;
`;

export const CenteredHeader = styled(StyledH2)`
  font-weight: normal !important;
  text-align: center;
  width: 100%;
  font-family: "Crimson Text" !important

`;

export const SloganText = styled(StyledP)`
  color: #333;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "Crimson Text";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  margin: 18px 0 15px;
`

export const CenteredText = styled(StyledP)`
  text-align: center;
  width: 100%;
  margin-bottom: 2rem;
`;

export const PasswordValidationWrapper = styled.div`
  margin-bottom: 2.25rem;
`;

export const PasswordValidationRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.25rem;

  p {
    margin: 0;
  }
`;

export const PasswordValidationIcon = styled(Icon)`
  margin: 0 0.25rem;
`;

export const StyledDiv = styled.div`
  p:nth-child(1) {
    margin-right: 0.5rem;
  } 
  p:nth-child(2) {
    margin-left: 0.5rem;
  } 
`;

export const InlineP =  styled(StyledP)`
  display: inline-block;
  font-weight: bold !important;
  text-decoration: underline;
  margin-top: 1.5rem;
  font-family: "Lato" !important;
  cursor: pointer;
`;
export const StyledLogo = styled.span`
  width: 115px;
  height: 48px;
  display: block;
  margin: auto;
`;

export const RelativeStyleLabel = styled(StyledLabel)`
  position: relative;
`;

export const PasswordMaskButtonWithIcon = styled(ButtonWithIcon)`
  position: absolute;
  right: 8px;
  margin: 0 !important;
  padding: 0 !important;
  top: -1px;
`;

export const AuthModalBackgroundOverlay = styled(GenericOverlay)`
  @media screen and (max-width: 767px) {
    background-image: url('https://au-assets.s3.us-west-2.amazonaws.com/assets/images/signInBackgroundMobile.png');
    top: 12rem;
  }

  @media screen and (min-width: 768px) {      
    background-image: url('https://au-assets.s3.us-west-2.amazonaws.com/assets/images/signInBackgroundDesktop.jpg');
  }

  background-size: cover;
`;