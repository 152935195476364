import React from 'react';
import { StyledH4, StyledP, StyledH3 } from 'components';
import styled from 'styled-components';
import { mediaQuery } from 'components';
import ContentLoader from 'react-content-loader';

export const ShippingSelectionHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  text-align: center;

  ${mediaQuery.medium`
    margin: 20px 0;
    text-align: left;
  `}
`;

export const ShippingMethodItemWrapper = styled.div`
  border-bottom: 1px solid black;
  padding-top: 1rem;
  padding-bottom: 1rem;

  &:last-child {
    border-bottom: none;
  }

  &:last-of-type {
    margin-bottom: 1.25rem;
  }
`;

export const ShippingMethodPrice = styled(StyledH4)`
  margin: 0;
  margin-left: auto;
  padding-top: 10px;
`;

export const ShippingTypeText = styled.div`
  padding-top: 0px;
  width: 80%;
  margin-left: 5px;
`;

export const HeaderText = styled(StyledH3)`
  margin: 0;
  margin-bottom: 0.5rem;
  margin-top: 1.5rem;
`;

export const SubheaderText = styled(StyledP)`
  margin: 0;
  color: #629eba;

  a:hover {
    text-decoration: underline;
  }
`;

export const ShippingMethod = styled.div`
  margin-left: 0.25rem;
  justify-content: space-between;

  h4,
  p {
    margin: 0;
  }

  h4 {
    margin-bottom: 0.5rem;
  }

  p {
    color: #6d6d6d;
    margin-bottom: 0.5rem;
  }
`;

export const HolidayNoteText = styled.span`
  margin: 0;
  margin-left: 2.5rem;
  color: red;
`;
export const ShippingMethodsSkeleton = () => {
  return (
    <ContentLoader height={160} width={250} speed={1} primaryColor="#f3f3f3" secondaryColor="#ecebeb">
      <circle cx="10" cy="20" r="8" />
      <rect x="25" y="15" rx="5" ry="5" width="220" height="10" />
      <circle cx="10" cy="50" r="8" />
      <rect x="25" y="45" rx="5" ry="5" width="220" height="10" />
      <circle cx="10" cy="80" r="8" />
      <rect x="25" y="75" rx="5" ry="5" width="220" height="10" />
      <circle cx="10" cy="110" r="8" />
      <rect x="25" y="105" rx="5" ry="5" width="220" height="10" />
    </ContentLoader>
  );
};

export const ErrorMessage = styled.span`
  color: red;
`;
