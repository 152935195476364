import { PrimaryLoadingButton } from 'components';
import { DisabledOverlay } from 'components/Billing/BillingForm/styled';
import AddressFormPartial from 'components/Forms/AddressFormPartial';
import { StyledForm, ButtonWrapper } from 'components/Forms/styled';
import ModalHeader from 'components/Modal/ModalHeader';
import { Formik, FormikProps } from 'formik';
import {
  AddressFormValues,
  AddressContactFormValues,
  addressFormValidator,
  initialFormValues,
  isAddressFormPopulated,
  initialContactFormValues,
  isAbbreviatedFormPopulated
} from 'helpers/forms';
import { buildAddressVerificationPayload } from 'helpers/shipping';
import CustomerDataAddress from 'mage-swagfaces/customer/CustomerDataAddress';
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { validateShippingAddress } from 'services/magenum/customer';
import { GlobalState } from 'store/constants';
import {
  hideModal,
  openShippingAddressVerificationModal as _openShippingAddressVerificationModal,
  sendModalData as _sendModalData
} from 'store/modal/actions';
import { currentModalDataSelector } from 'store/modal/selectors';

import { ShippingFormModal } from './styled';
import { useCountries } from 'hooks/useCountries';
import QuoteDataAddress from 'mage-swagfaces/quote/QuoteDataAddress';

export interface AddressData {
  name: string;
  line1: string;
  line2?: string;
  line3?: string;
  city: string;
  postalCode: string;
  region?: string;
  country: string;
  phone?: string;
  groupIds: string[];
  markedAsDeleted?: boolean;
}
function instanceOfAddressData(obj: any): obj is AddressData {
  return 'line1' in obj;
}

export interface ShippingAddressFormModalProps {
  modalData: { dirtyValues: QuoteDataAddress & AddressData };
  address?: QuoteDataAddress & AddressData;
  flashId?: string;
  flashToken?: string;
  openShippingAddressVerificationModal: (origAddr: CustomerDataAddress, suggAddr: CustomerDataAddress | false) => void;
  sendModalData?: (
    address: CustomerDataAddress | AddressFormValues,
    flashId?: string,
    flashToken?: string,
    id?: string
  ) => void;
  skipValidation?: boolean;
  abbreviated?: boolean;
  modalTitle?: string;
}

const ShippingAddressFormModal = ({
  address,
  openShippingAddressVerificationModal,
  sendModalData,
  modalData,
  flashId,
  flashToken,
  skipValidation,
  abbreviated,
  modalTitle
}: ShippingAddressFormModalProps) => {
  const countries = useCountries();
  if (!sendModalData) {
    sendModalData = (address: QuoteDataAddress) => dispatch(_sendModalData({ dirtyValues: address }));
  }
  const initialAddress = modalData.dirtyValues || address;
  const dispatch = useDispatch();
  //TODO: Fix type checks in here.
  return (
    <Formik
      initialValues={
        address && instanceOfAddressData(address)
          ? initialContactFormValues(address, countries)
          : initialFormValues(initialAddress, countries)
      }
      isInitialValid={!!address} // Ensures pre-filled forms are considered valid
      onSubmit={async (values: AddressFormValues) => {
        let payload = { address: null };
        if (skipValidation) {
          payload = { address: values };
        } else {
          payload = buildAddressVerificationPayload(values);
        }
        sendModalData(payload.address, flashId, flashToken, address?.id?.toString());
        if (!skipValidation) {
          await validateShippingAddress(payload).then(x => {
            x.map(validatedAddr => {
              openShippingAddressVerificationModal(payload.address, validatedAddr);
            }).getOrElseL(() => {
              openShippingAddressVerificationModal(payload.address, false);
            });
          });
        } else {
          dispatch(hideModal());
        }
      }}
      validate={addressFormValidator}
      render={(formikProps: FormikProps<AddressFormValues> & AddressContactFormValues) => {
        const { isSubmitting, values, isValid } = formikProps;
        let isPopulated = false;
        if (abbreviated) {
          isPopulated = isAbbreviatedFormPopulated(values);
        } else {
          isPopulated = isAddressFormPopulated(values);
        }

        let modalHeaderTitle = '';
        if (abbreviated) {
          modalHeaderTitle = 'Edit Contact';
        }
        if (!address?.name && !address?.firstname) {
          modalHeaderTitle = 'Add Shipping Address';
        }
        if (address?.firstname && address?.lastname) {
          modalHeaderTitle = `Edit ${modalTitle || 'Shipping Address'}`;
        }

        const submitButton = () => {
          if (abbreviated) {
            return 'Save';
          }
          if (!address) {
            return 'Add To Address Book';
          } else {
            return 'Save To Address Book';
          }
        };

        return (
          <ShippingFormModal>
            <DisabledOverlay>
              <ModalHeader title={modalHeaderTitle} />
              <StyledForm>
                <AddressFormPartial {...formikProps} abbreviated={abbreviated} />
                {abbreviated ? (
                  <ButtonWrapper>
                    <PrimaryLoadingButton type="submit" disabled={!isPopulated} isLoading={isSubmitting} width={100}>
                      {submitButton()}
                    </PrimaryLoadingButton>
                  </ButtonWrapper>
                ) : (
                  <PrimaryLoadingButton type="submit" disabled={!isPopulated || !isValid} isLoading={isSubmitting}>
                    {submitButton()}
                  </PrimaryLoadingButton>
                )}
              </StyledForm>
            </DisabledOverlay>
          </ShippingFormModal>
        );
      }}
    />
  );
};

const mapStateToProps = (state: GlobalState) => ({
  modalData: currentModalDataSelector(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  openShippingAddressVerificationModal: (origAddr: CustomerDataAddress, suggAddr: CustomerDataAddress) =>
    dispatch(_openShippingAddressVerificationModal({ origAddr, suggAddr }))
  //sendModalData: (address: QuoteDataAddress) => dispatch(_sendModalData({ dirtyValues: address }))
});

export { ShippingAddressFormModal as TestableShippingAddressFormModal };

export default connect(mapStateToProps, mapDispatchToProps)(ShippingAddressFormModal);
