import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { applyPromoCodeRequest, removePromoCodeRequest } from 'store/cart/actions';
import { isFetching } from 'store/constants';
import { promoCodeSelector, promoCodeErrSelector, promoCodeLoadingStateSelector } from 'store/cart/selectors';
import { CollapsibleBlock } from 'components';
import { PromoCodeWrap, SingleInputFormStateChange } from './styled';
import { getCookie } from 'helpers/http';
export interface PromoCodeProps {
  autoApplyStrikethroughValue?: string;
}

export const GOOGLE_SHOPPING_COOKIE = 'google-shopping';

const PromoCode = ({ autoApplyStrikethroughValue }: PromoCodeProps) => {
  const promoCode = useSelector(promoCodeSelector);
  const err = useSelector(promoCodeErrSelector);
  const loadingState = useSelector(promoCodeLoadingStateSelector);

  const dispatch = useDispatch();

  const [promoCodeValue, setPromoCodeValue] = useState(promoCode || '');
  const [error, setError] = useState('');

  const shouldAutoApplyPromoCode = getCookie(GOOGLE_SHOPPING_COOKIE);

  useEffect(() => {
    setError(err);
  }, [err]);

  useEffect(() => {
    setError('');
  }, [promoCodeValue]);

  useEffect(() => {
    setPromoCodeValue(promoCode || '');
  }, [promoCode]);

  useEffect(() => {
    if (autoApplyStrikethroughValue && !promoCode?.length && shouldAutoApplyPromoCode) {
      setPromoCodeValue(autoApplyStrikethroughValue);
      dispatch(applyPromoCodeRequest(autoApplyStrikethroughValue));
      setError('');
    }
  }, [autoApplyStrikethroughValue, shouldAutoApplyPromoCode]);

  const handleButtonLabel = (promoCodeApplied: boolean) => (promoCodeApplied ? 'Remove' : 'Apply');

  return (
    <PromoCodeWrap>
      <CollapsibleBlock title="Promo Code">
        <SingleInputFormStateChange
          disabled={!!promoCode}
          onChange={setPromoCodeValue}
          value={promoCodeValue}
          onSubmit={() => {
            if (promoCode) {
              dispatch(removePromoCodeRequest());
              setPromoCodeValue('');
            } else {
              if (promoCodeValue) {
                dispatch(applyPromoCodeRequest(promoCodeValue));
                setError('');
              } else {
                setError('Please enter a valid promo code');
              }
            }
          }}
          error={error}
          submitButtonLabel={handleButtonLabel(!!promoCode)}
          isLoading={isFetching(loadingState)}
        />
      </CollapsibleBlock>
    </PromoCodeWrap>
  );
};

export { PromoCode as TestablePromoCode };

export default PromoCode;
