import React, { useCallback, useState, useMemo } from 'react';
import { CTAButton, PromotionalMessaging } from 'components';
import { Show } from 'components/Functional';
import { PDPInput } from './PDPInput';
import { FormTitle, DSButton, DScontent, FreeAddressingBannerContainer } from './styled';
import Itly from 'itly';
import { convertToReportingProduct } from 'analytics/itly/helpers/product';
import { getShipCutoffContentBlock } from 'helpers/products';
import Block from 'components/CMS/Block';
import { useBlock } from '../../hooks/useBlock';
import { PDP_FORM_ID } from '../../helpers';
import { DESIGN_SERVICES_BOOKS } from 'components/constants/products';
import { useProductForm } from '../../hooks/useProductForm';
import { useProduct } from '../../hooks/useProduct';
import { useProductSelectors } from 'hooks/useProductSelectors';

import { useFormContext } from 'react-hook-form';
import { getGatsbySiteUrl } from 'helpers/env';
import { useSplitTreatment } from 'hooks/splits/useSplitTreatment';
import { SPLIT_IO_FEATURE_FLAG_ENVELOPE_ADDRESSING_BANNER, SPLIT_IO_FEATURE_FLAG_ENVELOPE_ADDRESSING_BANNER_COOKIE } from 'constants/split';
import { SPLIT_TRAFFIC_TYPES } from 'au-js-sdk/lib/services/split.io/constants';

type PDPFormProps = {
  displayNewPdpTop: boolean;
  displayState: string;
};

export const PDPForm = ({ displayNewPdpTop, displayState }: PDPFormProps): JSX.Element => {
  const { inputs, handleSubmit } = useProductForm();
  const { product } = useProduct();
  const { getSLACategory } = useProductSelectors();
  const { getValues } = useFormContext();
  const [ctaImpressionFired, setCtaImpressionFired] = useState(false);
  const { getBlock } = useBlock();

  const shipCutoffContentBlockId = useMemo(() => {
    const slaCategory = getSLACategory(product.sku);
    return slaCategory ? getShipCutoffContentBlock(slaCategory) : null;
  }, [getSLACategory, product.sku]);

  const isShipCutoffContentBlockActive = useMemo(
    () => shipCutoffContentBlockId != null && getBlock(shipCutoffContentBlockId)?.active,
    [shipCutoffContentBlockId, getBlock]
  );

  const ctaButtonOnVisible = useCallback(() => {
    if (!ctaImpressionFired) {
      setCtaImpressionFired(true);
      Itly.ctaImpression(convertToReportingProduct(product));
    }
  }, [ctaImpressionFired, product]);

  const isDSsku = DESIGN_SERVICES_BOOKS.find(book => book.sku === product.sku);

  const handleDesignItForMeClick = (dsUrl?: string) => {
    Itly.dsButtonClicked(convertToReportingProduct(product));
    window.open(`${getGatsbySiteUrl()}${dsUrl ? dsUrl : '/album-design-service'}`);
  };

  if (typeof window !== 'undefined' && typeof document !== 'undefined') {
    //Preload hover assets
    for (const input of inputs) {
      for (const optionName in input.options) {
        if (input.options[optionName].hoverImage) {
          new Image().src = input.options[optionName].hoverImage;
        }
      }
    }
  }

  const formCTALabel = getValues('has_prints') === '1' ? 'Create Your Prints' : product.addToCartLabel;

  const { treatmentStatus: envelopeAddressingBannerEnabled } = useSplitTreatment(
    SPLIT_IO_FEATURE_FLAG_ENVELOPE_ADDRESSING_BANNER,
    SPLIT_IO_FEATURE_FLAG_ENVELOPE_ADDRESSING_BANNER_COOKIE,
    SPLIT_TRAFFIC_TYPES.ANONYMOUS
  );

  const ADDITIONAL_PRODUCTS_WITH_ADDRESSING = ['53632', '53635'];
  const showFreeAddressingBanner = () => {
    const hasEnvelopeOption = inputs.some(input => input.id === 'envelope');
    const isHolidayCard = product.reportingProductLine === 'holiday cards';
    const isSpecialProduct = ADDITIONAL_PRODUCTS_WITH_ADDRESSING.includes(product.id);
  
    return hasEnvelopeOption && 
           (isHolidayCard || isSpecialProduct) && 
           envelopeAddressingBannerEnabled;
  }

  return (
    <>
      <Show when={!displayNewPdpTop}>
        <FormTitle>Make it Yours</FormTitle>
      </Show>
      <form id={PDP_FORM_ID} onSubmit={handleSubmit} style={{ paddingRight: '2px' }}>
        {inputs.map(input => (
          <PDPInput displayNewProductOptions={true} key={input.id} wrapTextSwatches={true} {...input} />
        ))}
      </form>
      <Show when={product.promotionalMessage && !isShipCutoffContentBlockActive}>
        <PromotionalMessaging>{product.promotionalMessage}</PromotionalMessaging>
      </Show>
      <Show when={isShipCutoffContentBlockActive}>
        <Block
          id={shipCutoffContentBlockId}
          render={content => <div dangerouslySetInnerHTML={{ __html: content }} />}
        />
      </Show>
      <Show when={showFreeAddressingBanner()}>
        <FreeAddressingBannerContainer />
      </Show>
      <CTAButton
        onClick={handleSubmit}
        addToCartLabel={formCTALabel}
        displayState={displayState}
        onVisible={ctaButtonOnVisible}
      />
      <Show when={isDSsku?.sku !== undefined}>
        <DSButton onClick={() => handleDesignItForMeClick(isDSsku?.url)} id={'design-services-button'}>
          DESIGN IT FOR ME
        </DSButton>
        <DScontent>Have one of our expert designers create the album you've always envisioned.</DScontent>
      </Show>
    </>
  );
};
