import React from 'react';
import Collapsible from 'react-collapsible';
import { optionGet } from 'faunctions';
import { MagentoMenuItem } from '../../types/Navigation';
import DropdownSection from '../Desktop/DropdownSection';
import { Icon } from 'components';

interface CollapsibleNavLinkProps {
  menuItem: MagentoMenuItem;
  isOpen: boolean;
  position: number;
  id: string;
  handleOpen: (pos: number) => void;
  handleClose: () => void;
}

const CollapsibleNavLink = ({ menuItem, isOpen, handleOpen, handleClose, position }: CollapsibleNavLinkProps) => {
  const menuItemName: string = optionGet('name')(menuItem).getOrElse('');
  const childrenMenuItems: Array<MagentoMenuItem> = optionGet('items')(menuItem).getOrElse([]);

  const NavLinkTrigger = (isOpen: boolean) => {
    const rotateDeg: number = isOpen ? 180 : 0;

    return (
      <div className="CollapsibleNavLink">
        <a>{menuItemName}</a>
        <div style={{ transform: `rotate(${rotateDeg}deg)` }}>
          <Icon icon="arrowDown" />
        </div>
      </div>
    );
  };

  return (
    <Collapsible
      easing="ease-in-out"
      open={isOpen}
      onOpening={() => handleOpen(position)}
      onClosing={handleClose}
      trigger={NavLinkTrigger(isOpen)}
    >
      {childrenMenuItems.map((cmi: MagentoMenuItem, i: number) => (
        <DropdownSection key={i} menuItem={cmi} parentName={menuItemName} />
      ))}
    </Collapsible>
  );
};

export default CollapsibleNavLink;
