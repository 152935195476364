import React, { FormEvent } from 'react';
import { RadioInputSelector, AddressBlock } from 'components';
import { AddressTileContent, SpacedText, AddressTile } from './styled';
import CustomerDataAddress from 'mage-swagfaces/customer/CustomerDataAddress';

interface AddressTileRadioProps {
  address: CustomerDataAddress
  value: string
  onChange: (event: FormEvent<HTMLInputElement>) => void
  checked: boolean
  handleEdit: Function
}

const AddressTileRadio = ({ address, value, onChange, checked, handleEdit }: AddressTileRadioProps) => (
  <AddressTile>
    <RadioInputSelector value={value} onChange={onChange} checked={checked}>
      <AddressTileContent>
        <AddressBlock address={address} />
        <SpacedText lightBlue onClick={() => handleEdit(address.id)}>
          EDIT
        </SpacedText>
      </AddressTileContent>
    </RadioInputSelector>
  </AddressTile>
);

export default AddressTileRadio;
