import React, { FC, useRef } from 'react';
import {
  BookSizeSwatch,
  CircularImageSwatch,
  FoilColorSwatch,
  OptionSelectDropdown,
  RadioInputGroupWrapper,
  SwatchProps,
  TextSwatch
} from 'components';
import { CSSObject } from 'styled-components';
import { format } from 'date-fns';
import DateSelect from 'components/DateSelect';
import TextInput from 'components/TextInput';
import { ProductAttribute, ProductAttributeOption, FieldRenderType } from '../../../helpers';
import { OnOptionMouseHandler } from '../../../hooks/useTooltip';
import { isProductAttributeOption } from '../../../helpers/attrs';
import { ProductOption } from 'features/pdp3/components/ProductOption';

export type PDPInputComponentProps = Omit<ProductAttribute, 'options'> & {
  options: ProductAttributeOption[];
  onChange?: (name: string, value: string) => void;
  selected?: ProductAttributeOption | string;
  backgroundUrl?: string;
  displayNewProductOptions: boolean;
  onOptionMouseOver?: OnOptionMouseHandler;
  onOptionMouseOut?: OnOptionMouseHandler;
  hideLabels?: boolean;
  wrapTextSwatches?: boolean;
  elementScrolled?: boolean;
};

const SWATCH_COMPONENTS: Record<string, FC<SwatchProps>> = {
  [FieldRenderType.SwatchText]: TextSwatch,
  [FieldRenderType.TextSwatch]: TextSwatch,
  [FieldRenderType.ImageSwatch]: CircularImageSwatch,
  [FieldRenderType.FoilSwatch]: FoilColorSwatch,
  [FieldRenderType.BooksizeSwatch]: BookSizeSwatch
};

export const PDPInputComponent = ({
  id,
  name,
  label,
  displayName,
  type,
  options,
  onChange,
  selected,
  backgroundUrl,
  displayNewProductOptions,
  onOptionMouseOver = () => null,
  onOptionMouseOut = () => null,
  hideLabels,
  wrapTextSwatches,
  elementScrolled = false
}: PDPInputComponentProps): JSX.Element => {
  const containerRef = useRef<HTMLDivElement>();

  if (type === FieldRenderType.OptionsSelect) {
    const selectOptions = options.map(({ id, label }) => ({
      value: id,
      label
    }));

    return (
      <OptionSelectDropdown
        id={id}
        key={id}
        name={name}
        label={label}
        value={selected}
        defaultValue={undefined}
        displayName={displayName}
        options={selectOptions}
        onChange={option => {
          const { value } = (option as unknown) as { value: string };
          // This is because `react-select` inject the value from the given list
          // Instead of using the value from the input
          onChange(name, value);
        }}
      />
    );
  }

  if (type === FieldRenderType.TextInput) {
    return (
      <TextInput
        id={id}
        label={label}
        value={!isProductAttributeOption(selected) ? selected : undefined}
        onChange={e => {
          onChange(name, e.target.value);
        }}
        maxLength={25}
      />
    );
  }

  if (type === FieldRenderType.Date) {
    const DATE_PICKER_FORMAT = 'yyyy-MM-dd';

    return (
      <DateSelect
        label={'Date to send'}
        dateFormat={DATE_PICKER_FORMAT}
        onChange={selectedDay => {
          onChange(name, format(selectedDay, DATE_PICKER_FORMAT));
        }}
      />
    );
  }

  const SwatchField = SWATCH_COMPONENTS[type] || TextSwatch;

  const style: CSSObject = {};
  if (type === FieldRenderType.HiddenInput) {
    style.display = 'none';
  }
  if (displayNewProductOptions) {
    return (
      <div ref={containerRef} data-type={type} data-testid={id} style={style}>
        <ProductOption
          id={id}
          name={name}
          label={label}
          wrapTextSwatches={wrapTextSwatches}
          displayName={displayName}
          containerRef={containerRef}
          type={type}
          required={false}
          backgroundUrl={backgroundUrl}
          options={options}
          selected={isProductAttributeOption(selected) ? selected : undefined}
          hoverLabel={isProductAttributeOption(selected) ? selected?.displayName || selected?.label : undefined}
          onChange={onChange}
          onOptionMouseOver={onOptionMouseOver}
          onOptionMouseOut={onOptionMouseOut}
          hideLabels={hideLabels}
          elementScrolled={elementScrolled}
        />
      </div>
    );
  }
  return (
    <div ref={containerRef} data-type={type} data-testid={id} style={style}>
      <RadioInputGroupWrapper
        id={id}
        label={label}
        displayName={displayName}
        hoverLabel={isProductAttributeOption(selected) ? selected?.displayName || selected?.label : undefined}
      >
        {options.map(option => (
          <SwatchField
            id={`${id}-${option.id}`}
            key={option.id}
            name={name}
            title={`${id}-${option.title}`}
            label={option.label}
            displayName={option.displayName}
            checked={isProductAttributeOption(selected) && selected?.id === option.id}
            mediaUrl={option.mediaUrl}
            backgroundUrl={backgroundUrl}
            disabled={option.disabled}
            onChange={option.disabled ? undefined : () => onChange(name, option.id)}
            onMouseOver={option.disabled ? undefined : e => onOptionMouseOver(option, containerRef, e)}
            onMouseOut={option.disabled ? undefined : e => onOptionMouseOut(option, containerRef, e)}
          />
        ))}
      </RadioInputGroupWrapper>
    </div>
  );
};
