import React, { FormEvent, useState } from 'react';
import { PrimaryButton, StyledH2 } from 'components';
import {
  findPaymentMethod,
  findCustomerPaymentMethodInCartPaymentMethods,
  CartPaymentMethod,
  paypal,
  amazonpay,
  CartAfterpayPaymentMethod
} from 'helpers/billing';
import { CustomerPaymentMethod } from 'au-types/lib/magento/sales/braintree/CustomerPaymentMethod';
import { connect, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { applyAfterpayPaymentMethod as _applyAfterpayPaymentMethod } from 'features/afterpay/store/actions';
import { applyPaymentMethod as _applyPaymentMethod } from 'store/cart/actions';
import {
  billingAddressSelector,
  cartPaymentMethodsSelector, cartSelector,
  isPaypalEnabledSelector
} from 'store/cart/selectors';
import { GlobalState } from 'store/constants';
import { customerPaymentMethodsSelector, defaultBillingAddressSelector } from 'store/customer/selectors';
import { hideModal as _hideModal, openBillingFormModal as _openBillingFormModal } from 'store/modal/actions';
import {
  PaymentMethodSelectionWrapper,
  AddNewPaymentMethodButton,
  NewCardText,
  PaymentMethodUpdateButtonWrapper
} from './styled';
import { AddressSelectionHeader, AddressTileList } from '../../Shipping/ShippingAddressSelection/styled';
import PaymentMethodTileRadio from './PaymentMethodTileRadio';
import CCIcon, { CCCardType } from '../CCIcon';
import { PAYMENT_METHOD_AFTERPAY } from 'au-types/lib/magento/sales/payment';
import useAfterpay from 'features/afterpay/hooks/useAfterpay';
import { AfterpayPaymentMethodTileRadio } from 'features/afterpay/components/AfterpayPaymentMethodTileRadio';
import {
  trackPaymentMethodChange
} from 'components/Billing/PaymentMethodSelection/helpers';

interface PaymentMethodSelectionProps {
  customerPaymentMethods: CustomerPaymentMethod[];
  cartPaymentMethods: CartPaymentMethod[];
  applyPaymentMethod: (pm: CustomerPaymentMethod) => void;
  applyAfterpayPaymentMethod: (pm: CartAfterpayPaymentMethod) => void;
  hideModal: () => void;
  openBillingFormModal: () => void;
}

const PaymentMethodSelection = ({
  customerPaymentMethods,
  cartPaymentMethods,
  applyPaymentMethod,
  applyAfterpayPaymentMethod,
  hideModal,
  openBillingFormModal
}: PaymentMethodSelectionProps) => {
  const isAmazonPayEnabled = false;
  const { isAfterpayEnabled } = useAfterpay();

  const customerPaymentMethod = findCustomerPaymentMethodInCartPaymentMethods(cartPaymentMethods);
  const isPaypalEnabled = useSelector(isPaypalEnabledSelector);

  const [paymentMethodToken, setPaymentMethodToken] = useState(
    isPaypalEnabled ? 'paypal' : customerPaymentMethod.token
  );

  const cart = useSelector(cartSelector);

  const defaultBillingAddress = useSelector(defaultBillingAddressSelector);
  const newBillingAddress = useSelector(billingAddressSelector);
  const billingAddress = defaultBillingAddress || newBillingAddress;

  const handleChange = (event: FormEvent<HTMLInputElement>) => {
    setPaymentMethodToken(event.currentTarget.value);
  };

  const handleUpdatePaymentClick = () => {
    const paymentMethod = [paypal, amazonpay, PAYMENT_METHOD_AFTERPAY].includes(paymentMethodToken)
      ? ({
        type: paymentMethodToken,
        token: paymentMethodToken,
        is_default: false,
        is_expired: false
      } as CustomerPaymentMethod)
      : findPaymentMethod(paymentMethodToken)(customerPaymentMethods);

    if (paymentMethod?.token === PAYMENT_METHOD_AFTERPAY) {
      applyAfterpayPaymentMethod({
        type: PAYMENT_METHOD_AFTERPAY,
        token: PAYMENT_METHOD_AFTERPAY,
        billingAddress
      });
    } else if (paymentMethod) {
      applyPaymentMethod(paymentMethod);
    }

    trackPaymentMethodChange(paymentMethod.type, cart);

    hideModal();
  };

  return (
    <>
      <PaymentMethodSelectionWrapper>
        <AddressSelectionHeader>
          <StyledH2 secondary>Change Payment Option</StyledH2>
        </AddressSelectionHeader>
        <AddressTileList>
          <AddNewPaymentMethodButton onClick={openBillingFormModal}>
            <NewCardText>Add New Card</NewCardText>
            <CCIcon type={CCCardType.ADD_NEW_CARD} />
          </AddNewPaymentMethodButton>
          {isAfterpayEnabled && (
            <AfterpayPaymentMethodTileRadio handleChange={handleChange} paymentMethodToken={paymentMethodToken} />
          )}
          {isAmazonPayEnabled && (
            <PaymentMethodTileRadio
              dataTestId="amazonpay-tile"
              paymentMethod={{
                type: amazonpay,
                token: amazonpay,
                is_default: false,
                is_expired: false
              }} // Fake AmazonPay "Payment Method"
              onChange={handleChange}
              checked={paymentMethodToken === amazonpay}
            />
          )}
          <PaymentMethodTileRadio
            dataTestId="paypal-tile"
            paymentMethod={{
              type: paypal,
              token: paypal,
              is_default: false,
              is_expired: false
            }} // Fake PayPal "Payment Method"
            onChange={handleChange}
            checked={paymentMethodToken === paypal}
          />
          {customerPaymentMethods.map((paymentMethod, index) => (
            <PaymentMethodTileRadio
              key={index}
              paymentMethod={paymentMethod}
              onChange={handleChange}
              checked={paymentMethodToken === paymentMethod.token}
            />
          ))}
        </AddressTileList>
      </PaymentMethodSelectionWrapper>
      <PaymentMethodUpdateButtonWrapper>
        <PrimaryButton onClick={handleUpdatePaymentClick}>
          Update Payment
        </PrimaryButton>
      </PaymentMethodUpdateButtonWrapper>
    </>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  customerPaymentMethods: customerPaymentMethodsSelector(state),
  cartPaymentMethods: cartPaymentMethodsSelector(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  applyPaymentMethod: (paymentMethod: CustomerPaymentMethod) => dispatch(_applyPaymentMethod(paymentMethod)),
  applyAfterpayPaymentMethod: (paymentMethod: CartAfterpayPaymentMethod) =>
    dispatch(_applyAfterpayPaymentMethod(paymentMethod)),
  openBillingFormModal: () => dispatch(_openBillingFormModal()),
  hideModal: () => dispatch(_hideModal())
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethodSelection);
