import { UseFormWatch } from 'react-hook-form';
import { ProductAttribute, ProductAttributeOption } from './attrs';
import { PDPFields } from '..';
import { __BREAKPOINT_SMALL__ } from 'components/constants/breakpoints';
import {
  MOBILE_DISPLAY_NEW_APP_DOWNLOAD_PROMPT_CTA,
  MOBILE_DISPLAY_UPLOAD_PHOTOS_CTA,
  MOBILE_DISPLAY_SHOW_ADD_BUTTON,
  YES_APP_NO_MOBILE,
  NO_APP_NO_MOBILE,
  YES_APP
} from 'components/constants/products';
import { isNotServerSideRendering } from 'components/helpers/conditionals';
import { buildPermutationSkuFromValues } from 'features/pdp/helpers/selectors';
import { Product } from 'types/Product';

export type HydratedPDPFields = Record<keyof PDPFields, ProductAttributeOption>;

type FieldValues = {
  [key: string]: string;
};

type InventoryItemMappedAttribute = {
  matchedPermutation: string | null;
  newInput: ProductAttribute | ProductAttributeOption;
};

export const PDP_FORM_ID = 'pdp-input-form';

export const HIDDEN_OPTIONS = ['app_foil_die', 'design'];

export const isAttributeHidden = ({ name }: ProductAttribute): boolean => HIDDEN_OPTIONS.includes(name);

export const dependentInputsPredicate = (values: PDPFields, skipIfNotInitialized?: boolean) => (
  input: ProductAttribute | ProductAttributeOption
): boolean => {
  const dependentFieldValue = values[input.dependsOnOption];

  return (
    !input.dependsOnOption ||
    (skipIfNotInitialized && !dependentFieldValue) ||
    input.dependsOnValues.includes(dependentFieldValue)
  );
};

export const getCustomOptionDefaults = (product: Product): PDPFields => {
  const defaults = {};
  product.customOptions?.forEach(option => {
    const attribute = product.attributes.find(attribute => attribute.name === option.title);
    const isVisible = !attribute?.hidden;
    if (isVisible) {
      defaults[option.title] = option.values[0]?.title;
    }
  });
  return defaults;
};

export const getDefaults = (product: Product): PDPFields => {
  const { sku, attributes, outOfStockPermutations } = product;

  const maybeOutOfStockPermutations = outOfStockPermutations || [];

  for (const attribute of attributes) {
    for (let i = 0; i < Object.keys(attribute.options).length; i++) {
      const valueMap = attributes.reduce((acc, attr) => {
        const sortedOptions = Object.keys(attr.options)
          .sort()
          .reduce((obj, key) => {
            obj[key] = attribute.options[key];
            return obj;
          }, {});
        const keyIndex = attribute.id === attr.id ? i : 0;
        const key = Object.keys(sortedOptions)[keyIndex];

        acc[attr.id] = attr.options[key].id;
        return acc;
      }, {});

      const permutation = buildPermutationSkuFromValues(product, sku, valueMap);
      const isOutOfStock = maybeOutOfStockPermutations.some(key => key === permutation);

      if (!isOutOfStock) {
        return valueMap;
      }
    }
  }

  return {};
};

export const inventoryItemStockMapper = (
  name: string,
  outOfStockPermutations: string[],
  formValues: FieldValues,
  defaults: FieldValues
) => (input: ProductAttribute | ProductAttributeOption): InventoryItemMappedAttribute => {
  let missingFormValueDefaults = {};

  if (Object.keys(formValues).length < Object.keys(defaults).length) {
    const missingFormkeys = Object.keys(defaults).filter(
      defaultKey => !Object.keys(formValues).find(formKey => defaultKey === formKey)
    );
    missingFormValueDefaults = missingFormkeys.reduce((acc, formKey) => {
      acc[formKey] = defaults[formKey];

      return acc;
    }, {});
  }

  // Find the permutation that represents the combination of the currently selected option values
  // and this potential one (input)
  const matchesSelectedFormValues = outOfStockPermutations.find(perm => {
    const keyValuePairs = Object.entries({ ...formValues, ...missingFormValueDefaults });

    return keyValuePairs.every(pair => {
      const [key, optionValue] = pair;
      return key === name || perm.split('-').includes(optionValue.toLowerCase());
    });
  });

  if (matchesSelectedFormValues?.length) {
    return {
      matchedPermutation: matchesSelectedFormValues,
      newInput: {
        ...input,
        disabled: true
      }
    };
  }

  return {
    matchedPermutation: null,
    newInput: input
  };
};

export const watchFields = (watch: UseFormWatch<PDPFields>, fields?: string[]): PDPFields =>
  !fields || !fields.length
    ? watch()
    : fields.reduce(
      (fields, field) => ({
        ...fields,
        [field]: watch(field)
      }),
      {}
    );

export const hydrateProductOptions = (attributes: ProductAttribute[], values: PDPFields): HydratedPDPFields => {
  return Object.entries(values).reduce((hydratedValues, [key, value]) => {
    const attr: ProductAttribute = attributes.find(({ id }) => key === id);
    return {
      ...hydratedValues,
      [key]: attr?.options[value]
    };
  }, {});
};

export const scrubValues = (prefix, values) => {
  return Object.keys(values).reduce((clean, current) => {
    // Upsell card input names are prefixed to keep the refs straight
    // Here, we remove the prefixes to get back to the pure attribute name
    if (current.includes('upsell-card')) {
      const properKey = current.replace(prefix, '');
      return {
        ...clean,
        [properKey]: values[current]
      };
    }
    return {
      ...clean,
      [current]: values[current]
    };
  }, {});
};

export const maybePrefix = (prefix: string, fields: string[]): string[] => {
  if (prefix && fields?.length) {
    return fields.map(field => prefix + field);
  }
  return fields;
};

export const getDisplayState = (
  mobileAvailability: string,
  isMobileFriendly: boolean,
  mobileOverride: boolean
): string => {
  const isMobile = !isNotServerSideRendering() || mobileOverride ? true : window.innerWidth <= __BREAKPOINT_SMALL__;

  switch (true) {
    case !isMobile:
      return MOBILE_DISPLAY_SHOW_ADD_BUTTON;
    case mobileAvailability === YES_APP_NO_MOBILE:
      return MOBILE_DISPLAY_NEW_APP_DOWNLOAD_PROMPT_CTA;
    case mobileAvailability === NO_APP_NO_MOBILE || (!mobileAvailability && !isMobileFriendly):
      return MOBILE_DISPLAY_UPLOAD_PHOTOS_CTA;
    case mobileAvailability === YES_APP:
      return isMobileFriendly ? MOBILE_DISPLAY_SHOW_ADD_BUTTON : MOBILE_DISPLAY_NEW_APP_DOWNLOAD_PROMPT_CTA;
    case isMobileFriendly:
      return MOBILE_DISPLAY_SHOW_ADD_BUTTON;
    case !isMobileFriendly:
      return MOBILE_DISPLAY_UPLOAD_PHOTOS_CTA;
    default:
      return MOBILE_DISPLAY_SHOW_ADD_BUTTON;
  }
};

export const scrollIntoFirstErrorInput = (firstErrorInputId: string): void => {
  if (firstErrorInputId) {
    try {
      let inputContainerElement = document.querySelector(`[name="${firstErrorInputId}"]`);
      while (inputContainerElement.parentElement.id !== PDP_FORM_ID) {
        inputContainerElement = inputContainerElement.parentElement;
      }

      inputContainerElement.scrollIntoView({ behavior: 'smooth' });
    } catch (error) {
      console.error('Error scrolling into form input: ', error);
    }
  }
};
