import styled from 'styled-components';
import { CalloutBlock } from 'components';
import { SingleInputForm } from 'components';

export const PromoCodeWrap = styled(CalloutBlock)`
  padding: 0;
  cursor: pointer;
`;

export const SingleInputFormStateChange = styled(SingleInputForm)`
  padding-top: 0rem;

  input[type='text']:disabled {
    opacity: 0.5;
  }

  button {
    height: 2.2rem;
    padding: 0rem;
    width: 4rem;
  }
`;
