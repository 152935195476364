import React from 'react';
import { MagentoMenuItem, MenuItemEdge } from '../../../../types/Navigation';
import SideMenu from './SideMenu';
import CartSvg from '../../../../icons/Cart.svg';
import LogoSvg from '../../../../icons/Logo.svg';
import SvgContainer from 'icons/SvgContainer';
import { Dispatch } from 'redux';

// @todo fix hamburger
import { Icon } from 'components';
import { Show } from 'components/Functional';
import { GlobalState } from 'store/constants';
import { cartItemsSelector } from 'store/cart/selectors';
import { connect } from 'react-redux';
import { openSearchModal as _openSearchModal, hideModal as _hideModal } from 'store/modal/actions';
import './Navigation.css';
import { trackSearchStarted } from 'analytics/itly/hooks';
import { AULink } from 'components/AULink';
import { InlineLink, StyledBurger } from './styled';
interface MobileNavProps {
  menuItems: Array<MagentoMenuItem>;
  cartItemCount: number;
  openSearchModal: (data: Array<MenuItemEdge>) => void;
}

interface MobileNavState {
  navExpanded: boolean;
}

class MobileNav extends React.Component<MobileNavProps, MobileNavState> {
  constructor(props: MobileNavProps) {
    super(props);

    this.state = {
      navExpanded: false
    };

    this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleMenu() {
    const { navExpanded } = this.state;
    const globalLayout = document.getElementsByClassName('GlobalLayout')[0];
    const body = document.getElementsByTagName('body')[0];

    if (navExpanded) {
      globalLayout.classList.remove('GlobalLayout__NavOpen');
      body.classList.remove('Body__NavOpen');
    } else {
      globalLayout.classList.add('GlobalLayout__NavOpen');
      body.classList.add('Body__NavOpen');
    }

    this.setState({ navExpanded: !navExpanded });
  }
  toggleSearch(menuItems, openSearchModal) {
    openSearchModal(menuItems);
  }
  render() {
    const { navExpanded } = this.state;
    const { menuItems, cartItemCount, openSearchModal } = this.props;

    const handleSearchClick = () => {
      trackSearchStarted();
      this.toggleSearch(menuItems, openSearchModal);
    };

    return (
      <div className="MobileNav__Wrapper">
        <a onClick={this.toggleMenu}>
          <StyledBurger icon="hamburger" />
        </a>
        <span style={{width: '124px'}}>
          <AULink id="logo-link" path="/">
            {SvgContainer(LogoSvg)}
          </AULink>
        </span>
        <span>
          <a onClick={handleSearchClick} className="searchLink">
            <Icon icon="magnifyingGlass" size="small" />
          </a>
          <InlineLink id="cart-mobile-link" path="/checkout/cart">
            {SvgContainer(CartSvg)}
            <Show when={!!cartItemCount}>
              <div
                style={{
                  display: 'inline-block',
                  fontSize: '11px',
                  paddingTop: '0.5rem',
                  verticalAlign: 'top',
                  color: '#f15223'
                }}
              >
                {cartItemCount}
              </div>
            </Show>
          </InlineLink>
        </span>
        {navExpanded ? <SideMenu menuItems={menuItems} toggleMenu={this.toggleMenu} /> : null}
      </div>
    );
  }
}

const mapStateToProps = (state: GlobalState) => ({
  cartItemCount: cartItemsSelector(state).length || 0
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  hideModal: () => dispatch(_hideModal()),
  openSearchModal: (menuItems?) => dispatch(_openSearchModal({ menuItems }))
});
export default connect(mapStateToProps, mapDispatchToProps)(MobileNav);
