import { BraintreeError, HostedFieldsStateObject, HostedFieldsTokenizePayload } from 'braintree-web';
import { Column, Grid, Icon, StyledLabel } from 'components';
import { optionGet } from 'faunctions';
import { FormikProps } from 'formik';
import {
  BILLING_FORM_ID,
  BILLING_FORM_SUBMIT_BUTTON_ID,
  BT_CARD_NUMBER_ID,
  BT_CVV_ID,
  BT_EXP_ID,
  isInvalid,
  isValid
} from 'helpers/billing';
import { showIf } from 'helpers/conditionals';
import { AddressFormValues } from 'helpers/forms';
import { objIsEmpty } from 'helpers/objects';
import { BraintreeClientType, useBraintree } from 'hooks/useBraintree';
import { PaymentMethodData } from 'mage-swagfaces/braintree/PaymentMethods';
import CustomerDataAddress from 'mage-swagfaces/customer/CustomerDataAddress';
import React, { useState } from 'react';

import {
  CCValidIcon,
  StyledBrainTreeField,
  StyledBillingForm
} from './styled';

import { newrelic } from 'helpers/reporting/newrelic';

interface BillingAddressFormPartialProps {
  formikProps: FormikProps<AddressFormValues>
  shippingAddress: CustomerDataAddress
  useSameAsShipping: boolean
  disabled?: boolean
  setUseSameAsShipping: (x: boolean) => void
  setAllCCFieldsValid: (x: boolean) => void
  braintreeValidationSuccess: (paymentMethod: PaymentMethodData['payment_method']) => void
  braintreeValidationFailure: (err: string) => void
  postcode: string
  cardholder: string
}

const BillingAddressFormPartial = ({
  setAllCCFieldsValid,
  braintreeValidationSuccess,
  braintreeValidationFailure,
  postcode,
  cardholder
}: BillingAddressFormPartialProps) => {
  const [hostedFieldsState, setHostedFieldsState] = useState();
  const number = optionGet('number')(hostedFieldsState).getOrElse('');
  const cvv = optionGet('cvv')(hostedFieldsState).getOrElse('');
  const expirationDate = optionGet('expirationDate')(hostedFieldsState).getOrElse('');

  const onValidationChangeHandler = (hostedFieldsState: HostedFieldsStateObject) => {
    const { fields } = hostedFieldsState;
    const { number: n, cvv: c, expirationDate: e } = fields;
    setHostedFieldsState(fields);

    if (!n.isValid || !c.isValid || !e.isValid) {
      setAllCCFieldsValid(false);
    } else {
      setAllCCFieldsValid(true);
    }
  };

  const hostedFieldsTokenizeHandler = (
    err: BraintreeError,
    payload: HostedFieldsTokenizePayload,
    deviceData: string
  ) => {
    if (err) {
      newrelic('addPageAction')('BRAINTREE_CC_TOKENIZE_ERROR', { err });
      braintreeValidationFailure(err.message);
      return;
    }

    braintreeValidationSuccess({
      nonce: payload.nonce,
      device_data: deviceData,
      is_default: false,
      cardholder,
      postcode
    });
  };

  const hostedFieldLocators = {
    btCardNumberId: BT_CARD_NUMBER_ID,
    btCvvId: BT_CVV_ID,
    btExpId: BT_EXP_ID,
    submitBtnId: BILLING_FORM_SUBMIT_BUTTON_ID
  };

  useBraintree(BraintreeClientType.HOSTED_FIELDS, {
    onValidationChangeHandler,
    hostedFieldsTokenizeHandler,
    hostedFieldLocators
  });

  return (
    <StyledBillingForm id={BILLING_FORM_ID}>
      <Grid>
        <Column size={12} medium={6}>
          <StyledLabel as="label">
            Card Number*
            <StyledBrainTreeField id={BT_CARD_NUMBER_ID}>
              <CCValidIcon>
                {showIf(isValid(number))(<Icon size="small" icon="check" color="green" />)}
                {showIf(isInvalid(number))(<Icon size="small" icon="close" color="red" />)}
              </CCValidIcon>
            </StyledBrainTreeField>
          </StyledLabel>
        </Column>
        <Column size={6} medium={3}>
          <StyledLabel as="label">
            Exp*
            <StyledBrainTreeField id={BT_EXP_ID}>
              <CCValidIcon>
                {showIf(isValid(expirationDate))(<Icon size="small" icon="check" color="green" />)}
                {showIf(isInvalid(expirationDate))(<Icon size="small" icon="close" color="red" />)}
              </CCValidIcon>
            </StyledBrainTreeField>
          </StyledLabel>
        </Column>
        <Column size={6} medium={3}>
          <StyledLabel as="label">
            CVV*
            <StyledBrainTreeField id={BT_CVV_ID}>
              <CCValidIcon>
                {showIf(isValid(cvv))(<Icon size="small" icon="check" color="green" />)}
                {showIf(isInvalid(cvv))(<Icon size="small" icon="close" color="red" />)}
              </CCValidIcon>
            </StyledBrainTreeField>
          </StyledLabel>
        </Column>
        
      </Grid>
      
    </StyledBillingForm>
  );
};

export default BillingAddressFormPartial;
